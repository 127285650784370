$primary-color: #ffd700;

body {
  overflow-y: scroll;
}

@import 'animate.css';
@import '.~loaders.css/src/animations/pacman.scss';

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
  
}
@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
  
}
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Italic-VariableFont_wght.ttf') format('ttf');
  
}
@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
  
}
@font-face {
  font-family: 'Lato-Italic';
  src: url('./assets/fonts/Lato-Italic.ttf') format('ttf'); 
}
@font-face {
  font-family: 'Lato-Regular';
  src: url('./assets/fonts/Lato-Regular.ttf') format('ttf'); 
}
@font-face {
  font-family: 'Lato-Light';
  src: url('./assets/fonts/Lato-Light.ttf') format('ttf'); 
}

input,
textarea {
  font-family: 'Helvetica Neue';
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}
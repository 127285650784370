.home-page {
  .text-zone {
    position: absolute;
    left: 5em;
    top: 50%;
    transform: translateY(-50%);
    width: 45%;
    max-height: 90%;
  }
  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    // &::before {
    //   content: '<h1>';
    //   font-family: 'La Belle Aurore';
    //   color: #ffd700;
    //   font-size: 18px;
    //   position: absolute;
    //   margin-top: -40px;
    //   left: 15px;
    //   opacity: 0.6;
    // }

    // &::after {
    //   content: '<h1 />';
    //   font-family: 'La Belle Aurore';
    //   color: #ffd700;
    //   font-size: 18px;
    //   margin-top: 18px;
    //   margin-left: 20px;
    //   opacity: 0.6;
    //   animation: fadeIn 1s 1.7s backwards;
    // }
  }

  .skills-container {
    border-top: 1px ridge #ffd700;
    border-bottom: 1px ridge #ffd700;
    padding: 25px 0px 25px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10%;
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 2em;
    font-family: 'La Belle Aurore';
    font-size: 1.2em;
    letter-spacing: 2px;
    color: #ffd700;
    animation-name: skillsAnimation;
    animation: pulse 2s;
    animation-duration: 8s;
    @keyframes skillsAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 13px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }
  .flat-button {
    color: #ffd700;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 40px;
    float: left;
    white-space: nowrap;

    &:hover {
      background: #ffd700;
      color: #333;
      opacity: 0.5;
    }
  }
  .kaseyImg {
    opacity: 0.5;
    filter: drop-shadow(0 0 1.2rem #ffd700);
    width: 30%;
    height: auto;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #ffd700;
    margin-left: 60%;
    margin-top: 20%;
    animation-name: picAnimation;
    animation-duration: 6s;
    @keyframes picAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 0.5;
      }
    }
  }
}

@media (max-width: 2500px) {
  .skills-container {
    border-top: 1px ridge #ffd700;
    border-bottom: 1px ridge #ffd700;
    padding: 25px 0px 25px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10%;
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 2em;
    font-family: 'La Belle Aurore';
    font-size: 1.2em;
    letter-spacing: 2px;
    color: #ffd700;
    animation-name: skillsAnimation;
    animation: pulse 2s;
    animation-duration: 8s;
    @keyframes skillsAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 13px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }
  .flat-button {
    color: #ffd700;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 40px;
    float: left;
    white-space: nowrap;

    &:hover {
      background: #ffd700;
      color: #333;
      opacity: 0.5;
    }
  }
  .kaseyImg {
    opacity: 0.5;
    filter: drop-shadow(0 0 1.2rem #ffd700);
    width: 30%;
    height: auto;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #ffd700;
    margin-left: 60%;
    margin-top: 20%;
    animation-name: picAnimation;
    animation-duration: 6s;
    @keyframes picAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 0.5;
      }
    }
  }
}

@media (max-width: 768px) {
  .home-page,
  .about-page {
    .text-zone {
      position: static;
      width: 50%;
      transform: none;

      margin-left: 5em;
      margin-bottom: 20px; // you can adjust this value for spacing between the text and image
    }
    h1 {
      font-size: 30px;
    }

    .kaseyImg {
      position: static;
      margin-left: 5em;
      margin-top: 0;
      width: 50%; // adjust this if you don't want the image to take full width on mobile
      display: block;
    }
    .skills-container {
      font-size: small;
      padding-bottom: 10px;
    }
  }
}
@media (max-width: 576px) {
  .home-page,
  .about-page {
    .text-zone {
      position: static;
      width: 50%;
      transform: none;

      margin-left: 5em;
      margin-bottom: 20px; // you can adjust this value for spacing between the text and image
    }
    h1 {
      font-size: 30px;
    }

    .kaseyImg {
      position: static;
      margin-left: 5em;
      margin-top: 0;
      width: 50%; // adjust this if you don't want the image to take full width on mobile
      display: block;
    }
    .skills-container {
      font-size: small;
      padding-bottom: 10px;
      width: 125%;
    }
    .flat-button {
      color: #ffd700;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #ffd700;
      margin-top: 10px;
      float: left;
      white-space: nowrap;
  
      &:hover {
        background: #ffd700;
        color: #333;
        opacity: 0.5;
      }
    }
  }
 
}

.projects-page .text-zone {
  width: 65%;

  h2 {
    opacity: 1;
    animation: fadeIn 2s 2s backwards;
  }
}

.flat-button {
  color: #ffd700;
  position: absolute;
  font-size: 11px;
  letter-spacing: 3px;
  text-decoration: none;
  padding: 8px 10px;
  border: 1px solid #ffd700;
  float: left;
  border-radius: 4px;
  background: 0 0;
  text-transform: uppercase;
  float: left;
  text-align: center;
  margin-right: 10px;
  opacity: 0;
  animation: backInRight 2s 2s;
  animation-fill-mode: forwards;

  &:hover {
    background: #ffd700;
    color: #333;
    opacity: 0.7;
  }
}

.project-paragraphs p {
  margin-bottom: 50px;

  // &::before {
  //   content: '<p>';
  //   color: #ffd700;
  //   font-family: La Belle Aurore;
  //   position: absolute;
  //   margin-top: -20px;
  //   opacity: 0.6;
  // }
  // &::after {
  //   content: '</p>';
  //   color: #ffd700;
  //   font-family: La Belle Aurore;
  //   position: absolute;
  //   margin-top: 20px;
  //   left: 0;
  //   opacity: 0.6;
  // }
}

.theory-images-container img {
  vertical-align: middle;
  width: 30%;
  margin-right: 3%;
  border: 2px solid #ffd700;
}

.theory-images-container img:nth-child(1) {
  opacity: 1;
  animation: fadeIn 2s 3s backwards;
}
.theory-images-container img:nth-child(2) {
  opacity: 1;
  animation: fadeIn 2s 3.5s backwards;
}
.theory-images-container img:nth-child(3) {
  width: 30%;
  margin-right: 1%;
  opacity: 1;
  animation: fadeIn 2s 4s backwards;
}

.danicas-paragraphs p {
  margin-bottom: 50px;
  // &::before {
  //   content: '<p>';
  //   color: #ffd700;
  //   font-family: La Belle Aurore;
  //   position: absolute;
  //   margin-top: -20px;
  //   opacity: 0.6;
  // }
  // &::after {
  //   content: '</p>';
  //   color: #ffd700;
  //   font-family: La Belle Aurore;
  //   position: absolute;
  //   margin-top: 20px;
  //   left: 0;
  //   opacity: 0.6;
  // }
}

.danicas-images-container img {
  width: 40%;
  margin-right: 5%;
  border: 2px solid #ffd700;
  margin-bottom: 3em;
}
.danicas-images-container img:nth-child(1) {
  opacity: 1;
  animation: fadeIn 2s 3s backwards;
}
.danicas-images-container img:nth-child(2) {
  opacity: 1;
  animation: fadeIn 2s 3.5s backwards;
}

@media (max-width: 576px) {
  .theory-images-container {
    display: none;
  }
  #projects-heading {
    font-size: 35px;
  }
  h2 {
    font-size: 20px !important;
  }
  p {
    font-size: 14px;
  }
  .danicas-images-container {
    display: none;
  }
  .flat-button {
    margin-left: 0;
    margin-top: 2%;
  }
  #theory-website-button {
    margin-top: 12%;
  }

}

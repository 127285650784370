.paragraphs p:nth-child(1),
p:nth-child(2),
p:nth-child(3),
p:nth-child(4) {
  // &::before {
  //   content: '<p>';
  //   color: #ffd700;
  //   font-family: La Belle Aurore;
  //   position: absolute;
  //   margin-top: -20px;
  //   opacity: 0.6;
  // }
  // &::after {
  //   content: '</p>';
  //   color: #ffd700;
  //   font-family: La Belle Aurore;
  //   position: absolute;
  //   margin-top: 20px;
  //   left: 0;
  //   opacity: 0.6;
  // }
}

//large screens
@media (max-width: 2500px) {
  .about-me-title {
    font-size: large;
  }
  #about-me-paragraphs {
    font-size: 20px;
    width: 250%;
  }
  .paragraphs p:nth-child(1),
  p:nth-child(2),
  p:nth-child(3),
  p:nth-child(4) {
    // &::before {
    //   content: '<p>';
    //   color: #ffd700;
    //   font-family: La Belle Aurore;
    //   position: absolute;
    //   margin-top: -25px;
    //   opacity: 0.6;
    // }
    // &::after {
    //   content: '</p>';
    //   color: #ffd700;
    //   font-family: La Belle Aurore;
    //   position: absolute;
    //   margin-top: 25px;
    //   left: 0;
    //   opacity: 0.6;
    // }
  }
}

//medium screens
@media (max-width: 768px) {
  .about-me-title {
    margin-left: 2.5em;
  }
  #about-me-paragraphs {
    margin-left: 6em;
    font-size: 14px;
    width: 70%;
  }

  .flat-button {
    margin-left: 7em;
  }
}

//small screens
@media (max-width: 576px) {
  .about-me-title {
    font-size: small;
    // margin-top: 100px;
    margin-left: 2.5em;
  }
  #about-me-paragraphs {
    margin-left: 6.5em;
    font-size: 12px;
    width: 65%;

    p {
      margin-bottom: 30px;
    }
  }
  #resume-btn {
    margin-left: 20%;
    width: 60%;
    font-size: 10px;
  }
}
